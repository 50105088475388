import React, { Fragment } from 'react';
// import About from './components/About';
import Section from '../../Section/Section';
import MeetingForm from '../../../components/Form/MeetingForm';
import aboutMeImg from '../../../assets/img/me/MaryoriBenitez.jpeg'

// import ContactUs from './components/ContactUs';
// import Alliances from './components/Alliances';

const AboutComponent = () => {
  return (
    <Section>
    {/* <Fade delay={300} direction="right"> */}
    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-1">
      <div className="col-span-1 md:col-span-1 xl:col-span-2 flex flex-col justify-center xl:ml-6">
        <h2 className="text-6xl xl:text-5xl text-center  text-primary font-bold">
            Sobre mí
          </h2>
        <div className='grid grid-cols-1 xl:grid-cols-2'>
        <div className="col-span-2 xl:col-span-1 flex flex-col">
          {/* <img
            src="https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/mujer.png"
            alt="about-img"
            className="rounded-full h-[200px] w-[200px] mt-10 mx-14 md:mx-2 md:w-[300px] md:h-[300px] xl:w-[360px] xl:h-[360px] md:mt-24 xl:mt-20 xl:mx-2"
          /> */}
          <img
            src={aboutMeImg}
            alt="about-img"
            className="object-cover rounded-full h-[200px] w-[200px] mt-10 mx-14 md:mx-2 md:w-[300px] md:h-[300px] xl:w-[350px] xl:h-[370px] md:mt-14 xl:mt-10 xl:mx-2"
          />
        </div>
        <div className="col-span-2 xl:col-span-1 ">
          <p className="text-lg xl:text-xl text-gray-700 mt-16 ml-0 xl:ml-1 xl:text-left">
            Somos profesionales con amplia trayectoria en el área inmobiliaria y comercial, capaces de brindarte una completa asesoría legal, respaldados con titulo de Magister en Derecho Inmobiliario y Registral en la Universidad Central de Chile y certificados en ACOP cómo tasadores, lo que nos permite acompañarte en el proceso de venta, arriendo o inversión de principio a fin.
            <br />
          </p>

          <p className="text-lg xl:text-xl text-gray-700 mt-8 ml-0 xl:ml-1 md:text-center xl:text-left">
            Maryori Benitez Valera.
          </p>
          <p className="text-xl xl:text-xl text-gray-700 ml-0 xl:ml-1 md:text-center xl:text-left">
            Fundador y Gerente General de Gestion Inmobiliaria .
          </p>
        </div>
    
        </div>
       
  
      </div>
      <div className="col-span-1 md:col-span-1 xl:col-span-1 flex flex-col justify-center mx-2 xl:ml-24">
      <MeetingForm 
            title="Contacto corredor"
            subtitle="Enviar mensaje"
          />
      </div>

      {/* 
        <div className="pt-10">
          <h2 className="text-2xl xl:text-4xl font-bold text-black text-center sm:text-start">
            “Un largo camino
            <br />
            se inicia con un solo paso...”
          </h2>
        </div> */}
    </div>
    {/* </Fade>  */}
  </Section>
    // <Fragment>
    //   <About />
    // </Fragment>
  );
};

export default AboutComponent;
